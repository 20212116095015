import RequestModel from '@apis/models/RequestModel';
import { createCancelToken } from '@apis/ServiceController';
import { reviewService } from '@services/reviewService';
import constant from '@utils/constant';
import { getProfileSelector } from '@utils/selectors';
import Rate from '@views/product-item/Rate';
import ContentLoading from '@views_admin/ContentLoading';
import { Col, Row } from 'antd';
import { Link } from 'gatsby';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useProductContext } from './ProductContext';
import ReviewBox from './ReviewBox';
import ReviewItem from './ReviewItem';
import { useLocation } from '@reach/router';
import Pagination from '@components/Pagination';
import { Role } from '@utils/enums/Role';
import { message } from '@components/Message';
import Breakpoint from '@components/Breakpoint/Breakpoint';

const Review = memo(({ props, isActive }) => {
   const location = useLocation();
   const { data: userProfile } = useSelector(getProfileSelector);
   const {
      uid: productUid,
      data: productData,
      loading: isProductLoading,
      reloadData,
   } = useProductContext();
   const { rate, reviews, bought, reviewed } = productData || {};

   const [loading, setLoading] = useState(false);
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      order_by: 'created_at',
      sort_type: 'DESC',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
      totalPages: 1,
   });
   const [showReviewBox, setShowReviewBox] = useState(false);
   // lock / delete reviews
   const [showEditReview, setShowEditReview] = useState(false);
   const [selectedReviews, setSelectedReviews] = useState([]);

   const cancelToken = useRef();
   const anchorTopRef = useRef();

   console.log(productData);

   useEffect(() => {
      const loadData = async () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
         await setLoading(true);
         cancelToken.current = createCancelToken();
         const body = new RequestModel([
            filter,
            {
               product_uid: productUid,
            },
         ]);
         const response = await reviewService.getReviewMatrix(
            body,
            cancelToken.current?.token
         );
         console.log(response);
         if (response.isSuccess) {
            const { content, totalRows, totalPages } = response.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
               totalPages,
            }));
         }
         setLoading(false);
      };

      if (isActive) {
         loadData();
      }
      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [isActive, filter, productUid]);

   // cancel review box
   const cancelReviewBox = useCallback(() => {
      setShowReviewBox(false);
   }, []);

   // handle submit review form
   const onAfterSubmitReview = useCallback(() => {
      setFilter((prevFilter) => ({ ...prevFilter, page: 1 }));
      setShowReviewBox(false);
      reloadData();
   }, [reloadData]);

   // handle submit a reply
   const onAddNewReply = useCallback(() => {
      setFilter((prevFilter) => ({ ...prevFilter }));
   }, []);

   // select item
   const handleSelectItem = useCallback(
      (uid) => {
         let currentSelected = [...selectedReviews];
         if (currentSelected.some((i) => i === uid)) {
            currentSelected = currentSelected.filter((i) => i !== uid);
         } else {
            currentSelected = [...currentSelected, uid];
         }
         setSelectedReviews(currentSelected);
      },
      [selectedReviews]
   );

   // lock reviews
   const lockReviews = useCallback(async () => {
      if (selectedReviews.length) {
         await setLoading(true);
         const response = await reviewService.lockReview({
            uids: selectedReviews,
         });
         console.log(response);
         if (response.isSuccess) {
            message.success('Lock reviews successfully.');
            setFilter((prevFilter) => ({ ...prevFilter, page: 1 }));
            setShowEditReview(false);
            setSelectedReviews([]);
         } else {
            message.error(response.message);
         }
         setLoading(false);
      }
   }, [selectedReviews]);

   // delete reviews
   const deleteReviews = useCallback(async () => {
      if (selectedReviews.length) {
         await setLoading(true);
         const response = await reviewService.deleteReview({
            uids: selectedReviews,
         });
         console.log(response);
         if (response.isSuccess) {
            message.success('Delete reviews successfully.');
            setFilter((prevFilter) => ({ ...prevFilter, page: 1 }));
            setShowEditReview(false);
            setSelectedReviews([]);
         } else {
            message.error(response.message);
         }
         setLoading(false);
      }
   }, [selectedReviews]);

   // can add review
   const canReview = useMemo(() => {
      if (
         (userProfile &&
            (userProfile.role_name === Role.MANAGER ||
               userProfile.role_name === Role.SYSTEM)) ||
         (userProfile && bought && !reviewed)
      ) {
         return true;
      }
      return false;
   }, [userProfile, bought, reviewed]);

   const onPaginationChange = useCallback(
      (page, pageSize) => {
         anchorTopRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
         setFilter({
            ...filter,
            page: page || filter.page,
            pageSize: pageSize || filter.pageSize,
         });
      },
      [filter]
   );

   if (!isActive) {
      return null;
   }

   return (
      <div className="product-review">
         <Row gutter={30}>
            <Col lg={7} md={24} sm={24} xs={24}>
               <div className="product-review__left animate__animated animate__fadeIn">
                  <label>{parseFloat(rate).toFixed(1)}</label>
                  <Rate point={rate} size={24} />
                  <div className="review-no">
                     {reviews} review{reviews > 1 ? 's' : ''}
                  </div>
                  {canReview && (
                     <div className="review-actions">
                        {userProfile ? (
                           <>
                              {!showReviewBox && (
                                 <button
                                    className="common-button__outline animate__animated animate__fadeIn"
                                    disabled={showEditReview}
                                    onClick={() => setShowReviewBox(true)}
                                 >
                                    WRITE A REVIEW
                                 </button>
                              )}
                              {data.content.length > 0 && (
                                 <button
                                    className={`${
                                       showEditReview
                                          ? 'common-button'
                                          : 'common-button__outline'
                                    } animate__animated animate__fadeIn`}
                                    disabled={showReviewBox}
                                    onClick={() =>
                                       setShowEditReview((prevValue) => !prevValue)
                                    }
                                 >
                                    {showEditReview ? 'CANCEL' : 'EDIT REVIEW'}
                                 </button>
                              )}
                           </>
                        ) : (
                           <div className="login-to-review">
                              Please
                              <Link
                                 to={`${constant.ROUTE_LOGIN}?${
                                    constant.backUrlQuery
                                 }=${encodeURIComponent(location.href)}`}
                              >
                                 &nbsp;Login&nbsp;
                              </Link>
                              to leave a review
                           </div>
                        )}
                     </div>
                  )}
               </div>
            </Col>
            <Col lg={17} md={24} sm={24} xs={24}>
               <div className="product-review__content">
                  <div ref={anchorTopRef} />
                  <ContentLoading isLoading={isProductLoading || loading} />
                  {showReviewBox && (
                     <ReviewBox
                        onCancel={cancelReviewBox}
                        onSubmit={onAfterSubmitReview}
                     />
                  )}
                  {showEditReview && (
                     <div className="product-review__selected animate__animated animate__fadeInDown">
                        <Breakpoint lg up>
                           <label>
                              {selectedReviews.length} review
                              {selectedReviews.length > 1 ? 's' : ''} selected
                           </label>
                        </Breakpoint>
                        <Breakpoint md down>
                           <label>{selectedReviews.length} selected</label>
                        </Breakpoint>
                        <div className="actions">
                           <button
                              className="btn-vape h40 lock"
                              disabled={selectedReviews.length === 0 || loading}
                              onClick={lockReviews}
                           >
                              Lock
                           </button>
                           <button
                              className="btn-vape h40 red"
                              disabled={selectedReviews.length === 0 || loading}
                              onClick={deleteReviews}
                           >
                              Delete
                           </button>
                        </div>
                     </div>
                  )}
                  {!loading && data.content.length === 0 && (
                     <>
                        {!showReviewBox && (
                           <div className="no-review animate__animated animate__fadeIn">
                              <label>There are no reviews yet</label>
                              <span>Be the first to review this product!</span>
                           </div>
                        )}
                     </>
                  )}
                  {data.content.length > 0 && (
                     <div className="review-list">
                        {data.content.map((reviewItem) => {
                           return (
                              <ReviewItem
                                 key={reviewItem.uid}
                                 {...reviewItem}
                                 productUid={productUid}
                                 onSubmit={onAddNewReply}
                                 isEditingReview={showEditReview}
                                 isSelected={selectedReviews.some(
                                    (i) => i === reviewItem.uid
                                 )}
                                 onSelect={handleSelectItem}
                              />
                           );
                        })}
                     </div>
                  )}
                  {data.totalPages > 1 && (
                     <div className="product-review__pagination">
                        <Pagination
                           page={filter.page}
                           pageSize={filter.pageSize}
                           total={data.totalRows}
                           onChange={onPaginationChange}
                        />
                     </div>
                  )}
               </div>
            </Col>
         </Row>
      </div>
   );
});

export default Review;
