import { cartService } from '@services/cartService';
import constant from '@utils/constant';
import { ProductStatus } from '@utils/enums/ProductStatus';
import Rate from '@views/product-item/Rate';
import { Col, Row } from 'antd';
import React, { useState, memo, useCallback, useMemo, useEffect } from 'react';
import IconAdd from '../../components/Icons/IconAdd';
import IconLess from '../../components/Icons/IconLess';
import { Link, navigate } from 'gatsby';
import { useProductContext } from './ProductContext';
import usePrevious from '@utils/hooks/usePrevious';
import { Collapse } from 'reactstrap';
import { message } from '@components/Message';
import { useSelector } from 'react-redux';
import { getProfileSelector } from '@utils/selectors';
import { storeDispatcher } from '@store/dispatcher';
import ProductShare from './ProductShare';
import Currency from '@components/Currency';
import AddToWishlist from './AddToWishlist';

const ProductInfo = memo(() => {
   const {
      uid: productId,
      data,
      optionsList,
      selectionInfo,
      selectedOption,
      changeSelectedOption,
   } = useProductContext();
   const { data: userProfile } = useSelector(getProfileSelector);
   const { price = 0, localPrice = 0, quantity, status, error } = selectionInfo || {};
   const { name, slug, rate, reviews } = data || {};

   const [number, setNumber] = useState(1);
   const [submitting, setSubmitting] = useState(false);
   const [showError, setShowError] = useState(false);
   const prevError = usePrevious(error);

   console.log(selectionInfo);

   useEffect(() => {
      if (prevError !== error && prevError && !error) {
         setShowError(false);
      }
   }, [error, prevError]);

   const onChangeNumber = (e) => {
      let value = Number(e.target.value);
      if (value < 1) {
         value = 1;
      } else if (value > quantity) {
         value = quantity;
      }
      setNumber(value);
   };

   const updownNumber = (isUp) => () => {
      setNumber((prevNumber) => (isUp ? prevNumber + 1 : prevNumber - 1));
   };

   const isOutOfStock = useMemo(() => {
      return status !== ProductStatus.AVAILABLE || quantity === 0;
   }, [status, quantity]);

   const renderStatus = useCallback(() => {
      if (isOutOfStock) {
         return <span className="out-of-stock">Out Of Stock</span>;
      }
      return <span className="in-stock">In Stock</span>;
   }, [isOutOfStock]);

   const handleChangeSelectedOption = useCallback(
      (uid, typeCode, disabled) => {
         if (!disabled) {
            changeSelectedOption(uid, typeCode);
         }
      },
      [changeSelectedOption]
   );

   /**
    * Option list
    */
   const renderOptions = useCallback(() => {
      if (!optionsList || optionsList?.length === 0) return;
      return (
         <div className="product-options">
            {optionsList.map((optionType) => {
               return (
                  <div className="product-option-type" key={optionType.code}>
                     <label className="product-option-type__title">
                        {optionType.name}
                     </label>
                     <div className="product-option-type__options">
                        {optionType.options.map((option) => {
                           const isActive =
                              selectedOption?.[optionType.code] === option.uid;
                           return (
                              <div
                                 className={`product-option-item ${
                                    isActive ? 'active' : ''
                                 } ${option.disabled ? 'disabled' : ''}`}
                                 key={option.uid}
                                 onClick={() =>
                                    handleChangeSelectedOption(
                                       option.uid,
                                       optionType.code,
                                       option.disabled
                                    )
                                 }
                              >
                                 <span>{option.name}</span>
                              </div>
                           );
                        })}
                     </div>
                  </div>
               );
            })}
         </div>
      );
   }, [optionsList, selectedOption, handleChangeSelectedOption]);

   // check can add to cart
   const canSubmitToCart = useMemo(() => {
      if (error || isOutOfStock) {
         return false;
      }
      return true;
   }, [error, isOutOfStock]);

   // handle add to cart
   const addToCart = useCallback(async () => {
      if (error) {
         setShowError(true);
      }
      if (!canSubmitToCart) {
         return;
      }

      await setSubmitting(true);
      const optionUid = selectionInfo?.productOptionUid || '';
      const item = {
         item_id: optionUid || productId,
         item_type: optionUid ? 'PRODUCT_OPTION' : 'PRODUCT',
         quantity: number || 1,
      };
      if (userProfile) {
         await cartService.add({
            items: [item],
         });
      } else {
         storeDispatcher.addToLocalCart(item);
      }
      setSubmitting(false);
      message.success('Product has been updated to your cart.');
   }, [selectionInfo, productId, number, error, canSubmitToCart, userProfile]);

   // buy now
   const buyNow = () => {
      addToCart();
      if (canSubmitToCart) {
         navigate(constant.ROUTE_CART);
      }
   };

   console.log(selectedOption);
   console.log(selectionInfo);
   console.log(data);

   return (
      <div className="product-info">
         <label className="product-name">{name || ''}</label>
         <div className="price">
            {/* <span className="old-price">2.450.000 đ</span> */}
            <span className="new-price">
               <Currency price={price} localPrice={localPrice} />
            </span>
         </div>
         <span className="product-rate">
            <Rate point={rate || 0} />
            <span className="count-review">{reviews || 0} reviews</span>
         </span>
         <div className="product-status">{renderStatus()}</div>
         {renderOptions()}

         <div className="product-amount">
            <div className="product-amount__group">
               <button
                  className="decrease"
                  onClick={updownNumber()}
                  disabled={number <= 1 || isOutOfStock}
               >
                  <IconLess />
               </button>
               <input
                  type="number"
                  value={number}
                  onChange={onChangeNumber}
                  disabled={isOutOfStock}
               />
               <button
                  className="increase"
                  onClick={updownNumber(true)}
                  disabled={number >= quantity || isOutOfStock}
               >
                  <IconAdd />
               </button>
            </div>
            <span className="product-quantity">{quantity} products available</span>
         </div>
         <Collapse isOpen={showError && error}>
            {<div className="product-submission-error">{error}</div>}
         </Collapse>
         <div className="product-actions">
            <Row gutter={30}>
               <Col lg={15} md={12} sm={12} xs={12}>
                  <button
                     className="common-button"
                     onClick={addToCart}
                     disabled={submitting || isOutOfStock}
                  >
                     ADD TO CART
                  </button>
               </Col>
               <Col lg={9} md={12} sm={12} xs={12}>
                  <button
                     className="common-button__outline"
                     onClick={buyNow}
                     disabled={submitting || isOutOfStock}
                  >
                     BUY NOW
                  </button>
               </Col>
            </Row>
         </div>
         <div className="product-sub-actions">
            <ProductShare slug={slug} productName={name} />
            <AddToWishlist productId={productId} />
         </div>
      </div>
   );
});

export default ProductInfo;
