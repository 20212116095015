import React, { memo } from 'react';
import { useProductContext } from './ProductContext';
import ReactHtmlParser from 'react-html-parser';

const Description = memo(({ isActive }) => {
   const { data } = useProductContext();

   const { description } = data || {};

   if (!isActive) {
      return null;
   }
   return (
      <p className="product-description">
         {description ? ReactHtmlParser(description) : 'There is no description for this product yet.'}
      </p>
   );
});

export default Description;
