import { productApi } from '@apis/productApi';
import { createCancelToken } from '@apis/ServiceController';
import { OptionType } from '@utils/enums/OptionType';
import { ProductStatus } from '@utils/enums/ProductStatus';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _forIn from 'lodash/forIn';
import _capitalize from 'lodash/capitalize';
import _orderBy from 'lodash/orderBy';
import _differenceWith from 'lodash/differenceWith';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

export const ProductContext = React.createContext();

const filterOptionList = (productOptions) => {
   let optionsData = {};
   let initialSelected = {};
   if (productOptions && productOptions?.length) {
      productOptions.forEach((proOption) => {
         if (proOption.options && proOption.options?.length) {
            proOption.options.forEach((option) => {
               if (option.type?.code) {
                  let isDisabled = Number(proOption.quantity) > 0 ? false : true;
                  if (isDisabled && optionsData?.[option.type.code]?.[option.uid]?.disabled === false) {
                     isDisabled = false;
                  }
                  optionsData[option.type.code] = {
                     ...(optionsData[option.type.code] || {}),
                     [option.uid]: {
                        uid: option.uid,
                        name: option.name,
                        typeCode: option.type.code,
                        disabled: isDisabled,
                     },
                  };
                  if (!initialSelected[option.type?.code]) {
                     initialSelected[option.type?.code] = '';
                  }
               }
            });
         }
      });
   }
   let optionsDataList = [];
   _forIn(optionsData, (value, key) => {
      optionsDataList.push({
         code: key,
         name: _capitalize(key),
         options: Object.values(value || {}),
      });
   });
   optionsDataList = _orderBy(optionsDataList, ['code'], ['desc']);
   return { optionsDataList, initialSelected };
};

export const useProductHandler = (slug) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(true);
   const [optionsList, setOptionsList] = useState([]);

   const canceller = useRef();

   const [selectedOption, setSelectedOption] = useState({});

   const { uid = '' } = data || {};

   useEffect(() => {
      const loadData = async () => {
         canceller.current = createCancelToken();
         await setLoading(true);
         const repsonse = await productApi.getProductByUid(null, slug, canceller.current?.token);
         if (repsonse.isSuccess) {
            setData(repsonse.data);
            const { optionsDataList, initialSelected } = filterOptionList(repsonse.data.product_options);
            setOptionsList(optionsDataList);
            setSelectedOption(initialSelected);
         }
         setLoading(false);
      };

      loadData();

      return () => {
         if (canceller.current) {
            canceller.current.cancel();
         }
      };
   }, [slug]);

   const selectionInfo = useMemo(() => {
      const { product_options } = data || {};

      console.log(product_options);
      let attachments = data?.attachments || [];
      const info = {
         productOptionUid: '',
         images: attachments,
         quantity: data?.quantity || 0,
         price: data?.price || 0,
         localPrice: data?.local_price || 0,
         status: data?.status || '',
         error: '',
      };
      const selectedOptionUids = [];
      if (selectedOption && !_isEmpty(selectedOption)) {
         _forIn(selectedOption, (uid, typeCode) => {
            if (uid) {
               selectedOptionUids.push(uid);
            }
         });
      }
      console.log(product_options);
      console.log(selectedOptionUids);
      if (product_options?.length) {
         // has options => need to select option
         info.error = 'Please select product classification';
         if (selectedOptionUids.length) {
            const currentProductOption = product_options.find((proOption) => {
               if (proOption?.options?.length === selectedOptionUids.length) {
                  const defOptions = proOption.options.map((i) => i.uid);
                  return _differenceWith(defOptions, selectedOptionUids, _isEqual).length === 0 ? true : false;
               }
               return false;
            });
            if (currentProductOption) {
               info.productOptionUid = currentProductOption.uid;
               info.images = [...(currentProductOption?.attachments || []), ...info.images];
               info.quantity = currentProductOption?.quantity || 0;
               info.price = currentProductOption?.price || 0;
               info.localPrice = currentProductOption?.local_price || 0;
               info.status = currentProductOption?.status || ProductStatus.SOLD;
               info.error = '';
            }
         }
      }

      return info;
   }, [data, selectedOption]);

   const changeSelectedOption = useCallback(
      (uid, typeCode) => {
         if (typeCode && uid) {
            setSelectedOption({
               ...selectedOption,
               [typeCode]: uid === selectedOption?.[typeCode] ? '' : uid,
            });
         }
      },
      [selectedOption]
   );

   const reloadData = useCallback(() => {
      const loadData = async () => {
         canceller.current = createCancelToken();
         const repsonse = await productApi.getProductByUid(null, slug, canceller.current?.token);
         if (repsonse.isSuccess) {
            setData(repsonse.data);
         }
      };

      loadData();

      return () => {
         if (canceller.current) {
            canceller.current.cancel();
         }
      };
   }, [slug]);

   return useMemo(
      () => ({
         uid,
         data,
         optionsList,
         loading,
         selectionInfo,
         selectedOption,
         changeSelectedOption,
         reloadData,
      }),
      [uid, data, optionsList, reloadData, loading, selectionInfo, selectedOption, changeSelectedOption]
   );
};

export const useProductContext = () => useContext(ProductContext);
