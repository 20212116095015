import { tokenUtil } from '@utils/token';
import { apiConstant } from '@apis/constant';
import { fetch } from '@apis/ServiceController';

const getReviewMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_REVIEW_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
      },
      cancelToken,
   });
};

const createReview = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_REVIEW,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const lockReview = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.LOCK_REVIEW,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const unlockReview = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UNLOCK_REVIEW,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const deleteReview = async (body, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_REVIEW,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

export const reviewService = {
   getReviewMatrix,
   createReview,
   lockReview,
   unlockReview,
   deleteReview,
};
