import { message } from '@components/Message';
import IconHeart from '@icons/IconHeart';
import { wishlistService } from '@services/wishlistService';
import { getProfileSelector } from '@utils/selectors';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useProductContext } from './ProductContext';

const AddToWishlist = memo(({ productId }) => {
   const { data: userProfile } = useSelector(getProfileSelector);
   const { data, reloadData } = useProductContext();
   const [submitting, setSubmitting] = useState(false);

   const { favorite = 0, liked = false, favorite_uid } = data || {};

   const addToWishlist = async () => {
      if (!userProfile) return;
      await setSubmitting(true);

      const response = await wishlistService.addProductToWishlist(productId);
      if (response.isSuccess) {
         await reloadData();
         message.success('Added to your Wishlist.');
      } else {
         message.error(response.message);
      }
      setSubmitting(false);
   };

   const deleteFromWishlist = async () => {
      if (!userProfile) return;
      await setSubmitting(true);

      const response = await wishlistService.deleteFromWishlist([favorite_uid]);
      if (response.isSuccess) {
         await reloadData();
         message.success('Removed from your Wishlist.');
      } else {
         message.error(response.message);
      }
      setSubmitting(false);
   };

   return (
      <button
         className={`add-to-wishlist ${liked ? 'favorited' : ''}`}
         onClick={!liked ? addToWishlist : deleteFromWishlist}
         disabled={submitting || !userProfile}
      >
         <IconHeart /> {favorite} like{favorite > 1 ? 's' : ''}
      </button>
   );
});

export default AddToWishlist;
