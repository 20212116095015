import React, { PureComponent } from 'react';

class IconHeart extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M0 6.67599C0 2.23613 4.44444 -0.427791 8 2.68011C11.5556 -0.427791 16 2.23612 16 6.67599C16 11.1159 9.77778 15.5557 8 15.5557C6.22222 15.5557 0 11.1159 0 6.67599Z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconHeart;