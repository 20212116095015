import React, { PureComponent } from 'react';

class IconLess extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path d="M0 8H16" stroke="currentColor" />
         </svg>
      );
   }
}

export default IconLess;