import React, { memo } from 'react';
import SEO from '../components/Common/SEO';
import { Row, Col } from 'antd';
import IconArrowRight1 from '../components/Icons/IconArrowRight1';
import Footer from '../views/default-layout/footer/Footer';
import ProductView from '../views/product/ProductView';
import { Link } from 'gatsby';
import { ProductContext, useProductHandler } from '@views/product/ProductContext';
import ContentLoading from '@views_admin/ContentLoading';
import Content from '@views/product/Content';
import constant from '@utils/constant';
import _isEmpty from 'lodash/isEmpty';
import Content404 from '@views/default-layout/404';
import IconArrow from '@icons/IconArrow';

const ProductDetail = memo((props) => {
   const slug = props.id;
   const productData = useProductHandler(slug);

   console.log('repsonse data ------', productData);
   const { name = '' } = productData?.data || {};

   const scrollTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
   };

   return (
      <>
         <ContentLoading isLoading={productData.loading} />
         {!productData.loading && (
            <>
               <SEO title={name || 'Page not found'} />
               {_isEmpty(productData.data) ? (
                  <>
                     <Content404 />
                     <Footer />
                  </>
               ) : (
                  <>
                     <ProductContext.Provider value={productData}>
                        <div className="product-detail-page">
                           <Row gutter={30} justify="center">
                              <Col xl={20} lg={24} md={24} sm={24} xs={24}>
                                 <div className="product-detail">
                                    <div className="breakcrumb">
                                       <Link
                                          className="breakcrumb-item"
                                          to={constant.ROUTE_PRODUCTS}
                                       >
                                          Products
                                       </Link>
                                       <i>
                                          <IconArrowRight1 />
                                       </i>
                                       <span className="breakcrumb-item active">
                                          {name}
                                       </span>
                                    </div>
                                    <Row gutter={30}>
                                       <ProductView />
                                    </Row>
                                 </div>
                                 <Content />
                              </Col>
                           </Row>
                        </div>
                     </ProductContext.Provider>
                     <Footer />
                     <div className="anchor-scrolltop">
                        <div className="layout-wrapper">
                           <button onClick={scrollTop}>
                              <IconArrow />
                           </button>
                        </div>
                     </div>
                  </>
               )}
            </>
         )}
      </>
   );
});

export default ProductDetail;
