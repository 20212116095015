import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import Description from './Description';
import Review from './Review';

const tabDesc = 'description';
const tabReview = 'review';

const tabList = [
   {
      label: 'Description',
      name: tabDesc,
      Component: Description,
   },
   {
      label: 'Review',
      name: tabReview,
      Component: Review,
   },
];

const Content = memo(() => {
   const [currentTab, setCurrentTab] = useState(tabDesc);
   const headerRef = useRef();

   // useEffect(() => {
   //    const checkFixedHeader = () => {
   //       if (headerRef.current) {
   //          const headerTop = headerRef.current.getBoundingClientRect().top;
   //          if (headerTop < 0) {
   //             headerRef.current.classList.add('fixed');
   //          } else {
   //             headerRef.current.classList.remove('fixed');
   //          }
   //       }
   //    };
   //    window.addEventListener('scroll', () => checkFixedHeader());

   //    return () => {
   //       window.removeEventListener('scroll', () => checkFixedHeader());
   //    };
   // }, []);

   return (
      <>
         <div className="product-main-content__header" ref={headerRef}>
            {tabList.map((tab) => {
               return (
                  <div
                     className={`main-tab ${tab.name === currentTab ? 'active' : ''}`}
                     key={tab.name}
                     onClick={() => setCurrentTab(tab.name)}
                  >
                     {tab.label}
                  </div>
               );
            })}
         </div>
         {tabList.map((tab) => {
            const isActive = tab.name === currentTab;
            return (
               <div className={`product-main-content__content ${isActive ? 'active' : ''}`} key={tab.name}>
                  <tab.Component isActive={isActive} />
               </div>
            );
         })}
      </>
   );
});

export default Content;
