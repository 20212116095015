import Avatar from '@components/Avatar';
import { message } from '@components/Message';
import { reviewService } from '@services/reviewService';
import { convertToDate } from '@utils/common';
import { InputType } from '@utils/enums/InputType';
import { Role } from '@utils/enums/Role';
import { getProfileSelector } from '@utils/selectors';
import Rate from '@views/product-item/Rate';
import React, { memo, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import IconCheck32 from '@icons/IconCheck32';

const ReviewItem = memo(
   ({ productUid, isEditingReview, isSelected, onSelect, onSubmit, ...props }) => {
      const { data: userProfile } = useSelector(getProfileSelector);

      const [showReplyBox, setShowReplyBox] = useState(false);
      const [submitting, setSubmitting] = useState(false);
      const [replyContent, setReplyContent] = useState('');
      const inputRef = useRef();

      const { uid, author, author_type, content, created_at, rate, children } =
         props || {};

      const handleSubmit = async () => {
         await setSubmitting(true);
         const body = {
            product_uid: productUid,
            parent_uid: uid,
            content: replyContent,
         };
         const response = await reviewService.createReview(body);
         console.log(response);
         if (response.isSuccess) {
            setShowReplyBox(false);
            setReplyContent('');
            onSubmit();
         } else {
            message.error(response.message);
         }
         setSubmitting(false);
      };

      const openReplyBox = () => {
         setShowReplyBox(true);
         setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
         }, 100);
      };

      const canReply = useMemo(() => {
         if (userProfile && userProfile.role_name !== Role.USER) {
            return true;
         }
         return false;
      }, [userProfile]);

      const renderReplyBox = () => {
         if (showReplyBox && canReply) {
            const canSubmit = `${replyContent}`.trim();
            return (
               <div className="reply-box animate__animated animate__fadeIn">
                  <div className="common-input-container">
                     <Input.TextArea
                        ref={inputRef}
                        className="common-input"
                        value={replyContent}
                        placeholder="Your reply"
                        onChange={(e) => setReplyContent(e.target.value)}
                     />
                  </div>
                  <div className="reply-box--actions">
                     <button
                        className="common-button__outline"
                        onClick={() => setShowReplyBox(false)}
                        disabled={submitting}
                     >
                        Cancel
                     </button>
                     <button
                        className="common-button"
                        onClick={handleSubmit}
                        disabled={submitting || !canSubmit}
                     >
                        Reply
                     </button>
                  </div>
               </div>
            );
         }
         return null;
      };

      console.log(children);

      return (
         <div className="product-review__review-item">
            <div className="author-avatar">
               <div
                  className={`checked-box ${isEditingReview ? 'active' : ''} ${
                     isSelected ? 'selected' : ''
                  }`}
                  onClick={() => onSelect(uid)}
               >
                  <IconCheck32 />
               </div>
               <Avatar
                  className={`avatar ${isEditingReview ? 'disabled' : ''}`}
                  src={author?.avatar_url || ''}
                  isManager={author_type === Role.MANAGER || author_type === Role.SYSTEM}
               />
            </div>
            <div className="review-author">
               <label className="author-name">
                  {author?.full_name || author?.username || ''}
               </label>
               <div className="review-rate">
                  <Rate point={rate || 0} />
                  <span className="date">{convertToDate(created_at || '')}</span>
               </div>
               <p className="review-desc">{content || ''}</p>
               {!isEditingReview && (
                  <>
                     {!showReplyBox && canReply && (
                        <div className="reply" onClick={openReplyBox}>
                           Reply
                        </div>
                     )}
                     {renderReplyBox()}
                     {children?.length !== 0 && (
                        <div className="reply-zone">
                           {children.map((replyItem) => {
                              const {
                                 uid,
                                 author,
                                 content,
                                 author_type,
                                 created_at,
                              } = replyItem;
                              return (
                                 <div className="product-review__reply-item" key={uid}>
                                    <div className="author-avatar">
                                       <Avatar
                                          className="avatar"
                                          src={author?.avatar_url || ''}
                                          isManager={
                                             author_type === Role.MANAGER ||
                                             author_type === Role.SYSTEM
                                          }
                                       />
                                    </div>
                                    <div className="reply-author">
                                       <label className="author-name">
                                          {author?.full_name || author?.username || ''}
                                       </label>
                                       <p className="reply-desc">{content}</p>
                                    </div>
                                 </div>
                              );
                           })}
                        </div>
                     )}
                  </>
               )}
            </div>
         </div>
      );
   }
);

export default ReviewItem;
