import React, { PureComponent } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const defaultAvatar = '../../assets/images/default_avatar.png';

const defaultOptions = {
   root: null,
   rootMargin: '0px',
   threshold: 0,
};

class Avatar extends PureComponent {
   state = {
      isError: false,
   };
   rendered = false;

   getStyle = () => {
      const { width, height, style = {} } = this.props;
      let styleObj = {};
      if (width) {
         styleObj.width = width;
      }
      if (height) {
         styleObj.height = height;
      }
      return { ...styleObj, ...style };
   };

   componentWillUnmount() {
      if (this.observer && this.imgElm) {
         this.observer.unobserve(this.imgElm);
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.src !== this.props.src) {
         if (this.rendered) {
            this.renderImage();
         }
      }
   }

   componentDidMount() {
      const options = { ...defaultOptions, ...(this.props?.options || {}) };
      this.observer = new IntersectionObserver(this.observerCallback, options);
      this.observer.observe(this.imgElm);
   }

   observerCallback = (entries) => {
      entries.forEach((entry) => {
         const { isIntersecting } = entry;
         if (isIntersecting && this.imgElm) {
            this.observer.unobserve(this.imgElm);
            this.rendered = true;
            this.renderImage();
         }
      });
   };

   setImgElm = (r) => {
      this.imgElm = r;
   };

   setImageSource = async (imgSrc, ratioWH) => {
      if (this.imgElm) {
         this.imgElm.setAttribute(`src`, `${imgSrc}`);
         this.props.keepRatio &&
            ratioWH &&
            this.imgElm.setAttribute(`height`, `${this.props.width / ratioWH}`);
         if (this.imgElm?.classList?.remove) {
            this.imgElm.classList.remove('loading');
         }
      }
   };

   renderImage = async () => {
      if (this.state.isError) {
         await this.setState({ isError: false });
      }
      let imgSrc = this.props.src;

      if (this.imgElm) {
         if (this.imgElm.classList.contains('loading')) {
            this.imgElm.classList.remove('loading');
         }
         this.imgElm.setAttribute('src', `${imgSrc}`);

         this.imgElm.onload = () => {
            if (this.props.keepRatio) {
               this.imgElm.style.objectFit = 'contain';
            }
            this.imgElm.classList.remove('loading');
         };
         this.imgElm.onerror = (err) => {
            this.setState({
               isError: true,
            });
         };
      }
   };

   setContainerRef = (r) => {
      this.containerRef = r;
   };

   render() {
      const style = {};
      if (this.props.width) {
         style.width = this.props.width;
      }
      if (this.props.height) {
         style.height = this.props.height;
      }
      return (
         <div
            className={`vgz-avatar ${this.props.className || ''} ${
               this.state.isError ? 'error' : ''
            }`}
            style={this.getStyle()}
            title={this.props.title || ''}
            ref={this.setContainerRef}
         >
            {!this.state.isError ? (
               <img
                  style={style}
                  className="vgz-avatar-img loading"
                  src={this.props.placeHolder}
                  ref={this.setImgElm}
                  alt={this.props.alt || ''}
               />
            ) : (
               <>
                  <StaticImage
                     className="vgz-avatar-img lazy-img-placeholder"
                     placeholder="tracedSVG"
                     src={defaultAvatar}
                     alt={this.props.alt || ''}
                  />
               </>
            )}
         </div>
      );
   }
}

export default Avatar;
