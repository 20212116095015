import React, { memo, useEffect, useRef, useState } from 'react';
import { Rate, Input } from 'antd';
import { useProductContext } from './ProductContext';
import { reviewService } from '@services/reviewService';
import { message } from '@components/Message';

const ReviewBox = memo(({ onCancel, onSubmit }) => {
   const { uid } = useProductContext();
   const [rate, setRate] = useState(0);
   const [content, setContent] = useState('');
   const [submitting, setSubmitting] = useState(false);
   const inputRef = useRef();

   useEffect(() => {
      if (inputRef.current) {
         inputRef.current.focus();
      }
   }, []);

   const submit = async () => {
      await setSubmitting(true);
      const body = {
         product_uid: uid,
         rate,
         content,
      };
      const response = await reviewService.createReview(body);
      console.log(response);
      if (response.isSuccess) {
         onSubmit();
      } else {
         message.error(response.message);
      }
      setSubmitting(false);
   };

   const canSubmit = content.trim() ? true : false;

   return (
      <div className="product-review__review-box animate__animated animate__fadeIn">
         <label className="review-title">WRITE A REVIEW</label>
         <Rate className="vape-rate" allowHalf value={rate} onChange={(v) => setRate(v)} />
         <div className="common-input-container">
            <Input.TextArea
               ref={inputRef}
               value={content}
               placeholder="Leave your review"
               className="common-input"
               onChange={(e) => setContent(e.target.value)}
            />
         </div>
         <div className="actions">
            <button className="common-button__outline" onClick={onCancel} disabled={submitting}>
               Cancel
            </button>
            <button className="common-button" disabled={!canSubmit || submitting} onClick={submit}>
               Submit
            </button>
         </div>
      </div>
   );
});

export default ReviewBox;
