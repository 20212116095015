import IconFacebook from '@icons/IconFacebook';
import IconInstagram from '@icons/IconInstagram';
import IconTwitter from '@icons/IconTwitter';
import React, { memo, useMemo } from 'react';
import { useLocation } from '@reach/router';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

const ProductShare = memo(({ slug, productName }) => {
   const { origin } = useLocation();

   const productUrl = useMemo(() => {
      return `${origin}/${slug}`;
   }, [slug, origin]);

   return (
      <div className="social-sharing">
         <FacebookShareButton url={productUrl} quote={productName} hashtag={'#vgzcustom'} className="sharing-button">
            <IconFacebook />
         </FacebookShareButton>
         <TwitterShareButton title={productName} url={productUrl} hashtags={['vgzcustom']} className="sharing-button">
            <IconTwitter />
         </TwitterShareButton>
         {/* <i>
            <IconInstagram />
         </i> */}
         {/* <IconYoutube /> */}
      </div>
   );
});

export default ProductShare;
