import React, { PureComponent } from 'react';

class IconArrowRight1 extends PureComponent {
   render() {
      return (
         <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path d="M4 2L8 6L4 10" stroke="currentColor" strokeLinejoin="round" />
         </svg>
      );
   }
}

export default IconArrowRight1;