import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Image, Row } from 'antd';
import ProductInfo from './ProductInfo';
import { useProductContext } from './ProductContext';
import LazyImage from '@components/LazyImage';
import { FileSize } from '@utils/enums/FileSize';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import PortalImagesView from './ImagesView/PortalImagesView';

const imgPlaceholder = '../../assets/images/img-placeholder.png';

const ProductView = memo(() => {
   const { selectionInfo } = useProductContext();

   const [imgIndex, setImgIndex] = useState(0);
   const [isOpenPortal, setIsOpenPortal] = useState(false);

   const { images } = selectionInfo || {};

   useEffect(() => {
      setImgIndex(0);
   }, [images]);

   const viewingImgSrc = images?.[imgIndex]?.formats?.[FileSize.MEDIUM] || '';

   const closePortal = useCallback(() => {
      setIsOpenPortal(false);
   }, []);

   const openViewPortal = () => {
      if (images?.length > 0) {
         setIsOpenPortal(true);
      }
   };

   const remainingImgs = useMemo(() => {
      if (images?.length) {
         return [...images].slice(4, images.length);
      }
   }, [images]);

   return (
      <>
         <Col lg={12} md={24} sm={24} xs={24}>
            <div className="product-view-container">
               {images && images?.length !== 0 ? (
                  <>
                     <div className="main-image" onClick={openViewPortal}>
                        <LazyImage
                           className="product-view-img animate__animated animate__fadeIn"
                           src={viewingImgSrc}
                        />
                     </div>
                     <div className="product-view-list">
                        {Array.from({ length: 5 }, (_, idx) => {
                           const imageItem = images?.[idx] || null;
                           const src = imageItem?.formats?.[FileSize.MEDIUM] || '';
                           const isRemaingItem = idx === 4 && remainingImgs.length > 1;
                           return (
                              <Fragment key={`${imageItem?.uid}_${idx}`}>
                                 {imageItem ? (
                                    <div className="child-img-wrapper-container">
                                       <div
                                          className={classNames('child-img-wrapper', {
                                             active: idx === imgIndex,
                                             'remaining-img': isRemaingItem,
                                          })}
                                          onClick={() => {
                                             setImgIndex(idx);
                                             if (isRemaingItem) {
                                                openViewPortal(0);
                                             }
                                          }}
                                       >
                                          {isRemaingItem && (
                                             <div className="remaining-number">
                                                {remainingImgs.length}+
                                             </div>
                                          )}
                                          <div className="child-img-wrapper__content">
                                             <LazyImage
                                                className="animate__animated animate__fadeIn"
                                                src={src}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 ) : (
                                    <></>
                                 )}
                              </Fragment>
                           );
                        })}
                     </div>
                  </>
               ) : (
                  <div className="main-image">
                     <StaticImage
                        className="no-image lazy-image-img lazy-img-placeholder"
                        placeholder="tracedSVG"
                        src={imgPlaceholder}
                        alt="No image"
                     />
                  </div>
               )}
            </div>
         </Col>
         <Col lg={12} md={24} sm={24} xs={24}>
            <ProductInfo />
         </Col>
         <PortalImagesView
            isOpen={isOpenPortal}
            startIndex={imgIndex}
            images={images}
            onClose={closePortal}
         />
      </>
   );
});

export default ProductView;
